var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BCard',[_c('BRow',{staticClass:"mx-1 flex gap-2 items-center"},[_c('BButton',{staticClass:"button-ajukan",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('img',{attrs:{"src":"https://storage.googleapis.com/komerce/assets/illustration/arrow-left-white.svg","alt":"left-arrow"}})]),_c('strong',{staticClass:"text-[20px] text-black font-semibold ml-1"},[_vm._v(" Ajukan File Pembanding ")])],1),_c('BRow',[_c('div',{staticClass:"ml-2 mt-2 text-[#333] disini"},[_vm._v(" Download Template Excel Pengecekan Rekonsiliasi "),_c('a',{attrs:{"href":_vm.templateLinkExcel,"target":"_blank"}},[_vm._v("\"Disini\"")])])]),_c('BRow',{staticClass:"gap-[8px] w-full"},[_c('ValidationObserver',{staticClass:"w-full max-w-[450px]",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('BCol',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":" text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tanggal Invoice ")]},proxy:true}],null,true)},[_c('ValidationProvider',{attrs:{"name":"Tanggal Invoice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FlatPickr',{staticClass:"datepicker-rekonsiliasi",attrs:{"config":{
                altInput: true,
                altFormat: 'j F Y',
                dateFormat: 'Y-m-d',
              },"placeholder":""},model:{value:(_vm.invoiceDate),callback:function ($$v) {_vm.invoiceDate=$$v},expression:"invoiceDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('BCol',{attrs:{"md":"12"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":" text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Pilih Ekspedisi ")]},proxy:true}],null,true)},[_c('ValidationProvider',{attrs:{"name":"expedition","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('VSelect',{staticClass:"rekonsiliasi-vselect",attrs:{"reduce":function (option) { return option.value; },"options":_vm.expeditionList,"filterable":true,"placeholder":"Pilih Ekspedisi"},model:{value:(_vm.idExpedition),callback:function ($$v) {_vm.idExpedition=$$v},expression:"idExpedition"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('BCol',{attrs:{"md":"12"}},[_c('BFormGroup',{attrs:{"label-cols-md":"4","label-class":"text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Upload File ")]},proxy:true}],null,true)},[_c('ValidationProvider',{ref:"provider",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-file',{ref:"file",staticClass:"hidden",attrs:{"type":"file","accept":".xls,xlsx,.xlsm,.xlsb,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},on:{"change":_vm.handleFileChange}}),_c('div',{staticClass:"border border-red py-[5px] px-[10px] rounded-[6px] flex justify-between"},[_c('div',{staticClass:"flex gap-[10px]"},[_c('button',{staticClass:" outline-none rounded-[6px] text-[12px] text-white px-[6px]",class:_vm.uploadFile ? 'bg-[#C2C2C2]' : 'bg-[#F95031]',on:{"click":_vm.handleClickUpload}},[_vm._v(" Upload File ")]),(_vm.uploadFile !== null)?_c('div',[_vm._v(" "+_vm._s(_vm.uploadFile.name)+" ")]):_vm._e(),(_vm.uploadFile === null)?_c('div',{staticClass:"text-[#C2C2C2]"},[_vm._v(" No file choosen ")]):_vm._e()]),(_vm.uploadFile !== null)?_c('button',{staticClass:"outline-none",on:{"click":_vm.resetUploadImage}},[_c('img',{attrs:{"src":"https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/close.svg","alt":"close"}})]):_vm._e()]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('BCol',[_c('button',{class:invalid || !_vm.uploadFile ? 'is_disable_button' : 'is_active_button',attrs:{"disabled":invalid || !_vm.uploadFile},on:{"click":function($event){$event.preventDefault();return _vm.submit()}}},[_vm._v(" "+_vm._s(!_vm.loadingSubmit ? 'Ajukan File Excel' : '')+" "),(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }