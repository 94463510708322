<template>
  <BCard>
    <BRow class="mx-1 flex gap-2 items-center">
      <BButton
        class="button-ajukan"
        size="sm"
        @click="$router.go(-1)"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/illustration/arrow-left-white.svg"
          alt="left-arrow"
        >
      </BButton>
      <strong class="text-[20px] text-black font-semibold ml-1">
        Ajukan File Pembanding
      </strong>
    </BRow>
    <BRow>
      <div class="ml-2 mt-2 text-[#333] disini">
        Download Template Excel Pengecekan Rekonsiliasi
        <a
          :href="templateLinkExcel"
          target="_blank"
        >"Disini"</a>
      </div>
    </BRow>

    <BRow class="gap-[8px] w-full">
      <ValidationObserver
        v-slot="{ invalid }"
        class="w-full max-w-[450px]"
      >
        <BCol
          md="12"
          class="mt-2"
        >
          <BFormGroup
            label-cols-md="4"
            label-class=" text-7 text-black"
          >
            <template #label>
              Tanggal Invoice
            </template>
            <ValidationProvider
              #default="{ errors }"
              name="Tanggal Invoice"
              rules="required"
            >
              <FlatPickr
                v-model="invoiceDate"
                class="datepicker-rekonsiliasi"
                :config="{
                  altInput: true,
                  altFormat: 'j F Y',
                  dateFormat: 'Y-m-d',
                }"
                placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </BFormGroup>
        </BCol>
        <BCol md="12">
          <BFormGroup
            label-cols-md="4"
            label-class=" text-7 text-black"
          >
            <template #label>
              Pilih Ekspedisi
            </template>
            <ValidationProvider
              #default="{ errors }"
              name="expedition"
              rules="required"
            >
              <VSelect
                v-model="idExpedition"
                :reduce="option => option.value"
                :options="expeditionList"
                class="rekonsiliasi-vselect"
                :filterable="true"
                placeholder="Pilih Ekspedisi"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </BFormGroup>
        </BCol>
        <BCol md="12">
          <BFormGroup
            label-cols-md="4"
            label-class="text-7 text-black"
          >
            <template #label>
              Upload File
            </template>
            <ValidationProvider
              ref="provider"
              v-slot="{ errors }"
              rules="required"
            >
              <b-form-file
                ref="file"
                type="file"
                accept=".xls,xlsx,.xlsm,.xlsb,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                class="hidden"
                @change="handleFileChange"
              />
              <div
                class="border border-red py-[5px] px-[10px] rounded-[6px] flex justify-between"
              >
                <div class="flex gap-[10px]">
                  <button
                    class=" outline-none rounded-[6px] text-[12px] text-white px-[6px]"
                    :class="uploadFile ? 'bg-[#C2C2C2]' : 'bg-[#F95031]'"
                    @click="handleClickUpload"
                  >
                    Upload File
                  </button>
                  <div v-if="uploadFile !== null">
                    {{ uploadFile.name }}
                  </div>
                  <div
                    v-if="uploadFile === null"
                    class="text-[#C2C2C2]"
                  >
                    No file choosen
                  </div>
                </div>
                <button
                  v-if="uploadFile !== null"
                  class="outline-none"
                  @click="resetUploadImage"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/close.svg"
                    alt="close"
                  >
                </button>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </BFormGroup>
        </BCol>
        <BCol>
          <button
            :disabled="invalid || !uploadFile"
            :class="
              invalid || !uploadFile ? 'is_disable_button' : 'is_active_button'
            "
            @click.prevent="submit()"
          >
            {{ !loadingSubmit ? 'Ajukan File Excel' : '' }}
            <b-spinner
              v-if="loadingSubmit"
              small
            />
          </button>
        </BCol>
      </ValidationObserver>
    </BRow>
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import FlatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import VSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import { required } from '@validations'
import { alertError, alertSuccess } from '@toast'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import { newAxiosIns } from '@/libs/axios'

export default {
  components: {
    ValidationProvider,
    FlatPickr,
    VSelect,
    ValidationObserver,
  },
  data() {
    return {
      loadingSubmit: false,
      templateLinkExcel:
        'https://storage.googleapis.com/komerce/assets/Template%20Reconciliation.xlsx',
      name: '',
      uploadFile: null,
      notes: '',
      idExpedition: null,
      expeditionList: [
        {
          label: 'JNE',
          value: 'JNE',
        },
        {
          label: 'SiCepat',
          value: 'SICEPAT',
        },
        {
          label: 'IDExpress',
          value: 'IDEXPRESS',
        },
        {
          label: 'Ninja',
          value: 'NINJA',
        },
        {
          label: 'J&T',
          value: 'JnT',
        },
        {
          label: 'SAP',
          value: 'SAP',
        },
      ],
      alertError,
      alertSuccess,
      submitErrors: '',
      invoiceDate: null,
      required,
    }
  },
  methods: {
    async handleFileChange(e) {
      const { valid } = await this.$refs.provider.validate(e)

      if (valid) {
        const fileExcell = this.$refs.file.files[0]
        this.uploadFile = fileExcell
      }
    },
    handleClickUpload() {
      this.$refs.file.$el.childNodes[0].click()
    },
    resetUploadImage() {
      this.$refs.file.reset()
      this.uploadFile = null
    },
    async submit() {
      this.loadingSubmit = true

      const formData = new FormData()
      formData.append('invoice_date', this.invoiceDate)
      formData.append('shipping', this.idExpedition)
      formData.append('reconciliation_file', this.uploadFile)

      const url = '/reconciliation/api/v1/reconciliations'
      await newAxiosIns
        .post(url, formData)
        .then(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'File Berhasil Diajukan',
              text: 'File berhasil diajukan',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.loadingSubmit = false
          this.$router.push({ name: 'rekonsiliasi' })
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'File Gagal Diajukan',
                icon: 'AlertCircleIcon',
                text: 'File gagal diajukan',
                variant: 'danger',
              },
            },
            { timeout: 1000 },
          )
          this.loadingSubmit = false
        })
    },
  },
}
</script>

<style  scoped>
@import './Rekonsiliasi.scss';

</style>
